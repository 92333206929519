import { Alert, AlertTitle } from '@mui/material'
import React from 'react'

import * as S from './styled'

type Props = {
    values: {
        success: boolean
        show: boolean
    }
}

const InfoBanner: React.FC<Props> = ({ values }) => {
    return (
        <S.InfoBanner display={values.show}>
            {values.success ? (
                <Alert severity='success' >
                    <AlertTitle>Úspěšně odesláno</AlertTitle>
                    Děkuji za Váš email. Byl úspěšně odeslán a budu Vás co nejdříve kontaktovat.
                </Alert>
            ) : (
                <Alert severity='error' >
                    <AlertTitle>Chyba</AlertTitle>
                    E-mail se nepodařilo doručit. Prosím kontaktujte mě na +420 608 762 023 nebo beestyle@gmail.com
                </Alert>
            )}
        </S.InfoBanner>
    )
}

export default InfoBanner