export const PublicImages = {
    kristianek: 'photos/kristianek.jpg',
    kristianek2: 'photos/kristianek2.jpg',
    kristianek3: 'photos/kristianek3.jpg',
    poster: 'photos/poster.jpg',
    sponzors: {
        optika_wagner: 'photos/sponzors/optikawagner.png',
        agel_nadace: 'photos/sponzors/agelnadace.png',
        peugeot: 'photos/sponzors/peugeot.png',
        eximtours: 'photos/sponzors/eximtours.svg',
        dekazlasky: 'photos/sponzors/dekazlasky.jpg',
        dobryandel: 'photos/sponzors/dobryandel.png',
        ranapece: 'photos/sponzors/ranapece.png',
        ochana: 'photos/sponzors/ochana.svg',
        cernikone: 'photos/sponzors/cernikone.svg',
        dobremistoprozivot: 'photos/sponzors/dobremistoprozivot.png',
        rejnet: 'photos/sponzors/rejnet.png',
        beestyle: 'photos/sponzors/beestyle.png',
        justcs: 'photos/sponzors/justcs.png',
        kuraray: 'photos/sponzors/kuraray.png',
        ksm: 'photos/sponzors/ksm.png',
        roadventurerally: 'photos/sponzors/roadventurerally.png',
        nadaceagrofert: 'photos/sponzors/nadaceagrofert.png'
    },
    qr: 'photos/qr.png',
    andilek: 'photos/andilek.jpg',
    facebook: 'photos/facebook.svg',
    instagram: 'photos/instagram.svg',
    articles: {
        zezivota: [
            'photos/zezivota/zezivota1.jpg',
            'photos/zezivota/zezivota2.jpg',
            'photos/zezivota/zezivota3.jpg',
            'photos/zezivota/zezivota4.jpg',
            'photos/zezivota/zezivota5.jpg',
            'photos/zezivota/zezivota7.jpg',
            'photos/zezivota/zezivota8.jpg',
            'photos/zezivota/zezivota9.jpg',
            'photos/zezivota/zezivota10.jpg',
            'photos/zezivota/zezivota11.jpg',
            'photos/zezivota/zezivota12.jpg',
            'photos/zezivota/zezivota13.jpg',
            'photos/zezivota/zezivota14.jpg',
            'photos/zezivota/zezivota15.jpg',
            'photos/zezivota/zezivota16.jpg',
            'photos/zezivota/zezivota17.jpg',
            'photos/zezivota/zezivota18.jpg',
            'photos/zezivota/zezivota19.jpg',
            'photos/zezivota/zezivota20.jpg',
            'photos/zezivota/zezivota21.jpg',
            'photos/zezivota/zezivota22.jpg',
            'photos/zezivota/zezivota23.jpg',
            'photos/zezivota/zezivota24.jpg',
            'photos/zezivota/zezivota25.jpg',
            'photos/zezivota/zezivota26.jpg',
            'photos/zezivota/zezivota27.jpg',
            'photos/zezivota/zezivota28.jpg',
            'photos/zezivota/zezivota29.jpg',
            'photos/zezivota/zezivota30.jpg',
            'photos/zezivota/zezivota31.jpg',
            'photos/zezivota/zezivota32.jpg',
            'photos/zezivota/zezivota34.jpg',
            'photos/zezivota/zezivota35.jpg',
            'photos/zezivota/zezivota36.jpg',
            'photos/zezivota/zezivota37.jpg',
            'photos/zezivota/zezivota40.jpg',
            'photos/zezivota/zezivota41.jpg',
            'photos/zezivota/zezivota42.jpg',
            'photos/zezivota/zezivota43.jpg',
            'photos/zezivota/zezivota44.jpg',
            'photos/zezivota/zezivota45.jpg',
            'photos/zezivota/zezivota46.jpg',
            'photos/zezivota/zezivota47.jpg',
            'photos/zezivota/zezivota48.jpg',
        ],
        handbike: [
            'photos/articles/handbike/bike.jpg',
            'photos/articles/handbike/contract.jpg',
            'photos/articles/handbike/voucher.jpg',
            'photos/articles/handbike/bike2.jpg',
        ],
        dekazlasky: [
            'photos/articles/dekazlasky/deka7.jpeg',
            'photos/articles/dekazlasky/deka1.jpeg',
            'photos/articles/dekazlasky/deka2.jpg',
            'photos/articles/dekazlasky/deka3.jpg',
            'photos/articles/dekazlasky/deka4.jpg',
            'photos/articles/dekazlasky/deka5.jpeg',
            'photos/articles/dekazlasky/deka6.jpeg'
        ],
        optika: [
            'photos/articles/optika/optika2.jpg',
            'photos/articles/optika/optika3.jpg',
            'photos/articles/optika/optika.jpg'
        ],
        agel: [
            'photos/articles/agel/agel2.jpg',
            'photos/articles/agel/agel1.jpg',

        ],
        denik: [
            'photos/articles/denik/denik1.jpeg',
            'photos/articles/denik/denik2.jpeg',
            'photos/articles/denik/denik3.jpeg',
            'photos/articles/denik/denik4.jpeg',
            'photos/articles/denik/denik5.jpeg'
        ],
        makarska2018: [
            'photos/articles/makarska2018/makarska1.jpg',
            'photos/articles/makarska2018/makarska2.jpg',
            'photos/articles/makarska2018/makarska3.jpg',
            'photos/articles/makarska2018/makarska4.jpg',
            'photos/articles/makarska2018/makarska5.jpg',
            'photos/articles/makarska2018/makarska6.jpg',
            'photos/articles/makarska2018/makarska7.jpeg'
        ],
        slunicko: [
            'photos/articles/slunicko/slunicko.jpg'
        ],
        makarska2017: [
            'photos/articles/makarska2017/makarska1.jpg',
            'photos/articles/makarska2017/makarska2.jpg',
            'photos/articles/makarska2017/makarska3.jpg',
            'photos/articles/makarska2017/makarska4.jpg',
            'photos/articles/makarska2017/makarska5.jpg',
            'photos/articles/makarska2017/makarska6.jpg',
            'photos/articles/makarska2017/makarska7.jpg',
            'photos/articles/makarska2017/makarska8.jpg',
            'photos/articles/makarska2017/makarska9.jpeg'
        ],
        kastel_stafilic: [
            'photos/articles/kastelstafilic/kastel1.jpg',
            'photos/articles/kastelstafilic/kastel2.jpg',
            'photos/articles/kastelstafilic/kastel3.jpg',
            'photos/articles/kastelstafilic/kastel4.jpg',
            'photos/articles/kastelstafilic/kastel5.jpg',
            'photos/articles/kastelstafilic/kastel6.jpg',
            'photos/articles/kastelstafilic/kastel7.jpg',
            'photos/articles/kastelstafilic/kastel8.jpg',
            'photos/articles/kastelstafilic/kastel9.jpg',
            'photos/articles/kastelstafilic/kastel10.jpg'
        ],
        kinderkamionaci: [
            'photos/articles/kinderkamionaci/kinderkamionaci1.jpg',
            'photos/articles/kinderkamionaci/kinderkamionaci2.jpg'
        ],
        makarska2019: [
            'photos/articles/makarska2019/makarska1.jpg',
            'photos/articles/makarska2019/makarska2.jpg',
            'photos/articles/makarska2019/makarska3.jpg',
            'photos/articles/makarska2019/makarska4.jpg',
            'photos/articles/makarska2019/makarska5.jpg',
            'photos/articles/makarska2019/makarska6.jpg',
            'photos/articles/makarska2019/makarska7.jpg'
        ],
        makarska2021: [
            'photos/articles/makarska2021/makarska8.jpg',
            'photos/articles/makarska2021/makarska1.jpg',
            'photos/articles/makarska2021/makarska2.jpg',
            'photos/articles/makarska2021/makarska3.jpg',
            'photos/articles/makarska2021/makarska4.jpg',
            'photos/articles/makarska2021/makarska5.jpg',
            'photos/articles/makarska2021/makarska6.jpg',
            'photos/articles/makarska2021/makarska7.jpg',
            'photos/articles/makarska2021/makarska9.jpg',
            'photos/articles/makarska2021/makarska10.jpg',
            'photos/articles/makarska2021/makarska11.jpg',
            'photos/articles/makarska2021/makarska12.jpg',
            'photos/articles/makarska2021/makarska13.jpg',
            'photos/articles/makarska2021/makarska14.jpg',
            'photos/articles/makarska2021/makarska15.jpg',
        ],
        makarska2022: [
            'photos/articles/makarska2022/makarska3.jpg',
            'photos/articles/makarska2022/makarska1.jpg',
            'photos/articles/makarska2022/makarska2.jpg',
            'photos/articles/makarska2022/makarska4.jpg',
            'photos/articles/makarska2022/makarska5.jpg',
            'photos/articles/makarska2022/makarska6.jpg',
            'photos/articles/makarska2022/makarska7.jpg',
            'photos/articles/makarska2022/makarska8.jpg',
            'photos/articles/makarska2022/makarska9.jpg',
            'photos/articles/makarska2022/makarska10.jpg',
            'photos/articles/makarska2022/makarska11.jpg',
            'photos/articles/makarska2022/makarska12.jpg',
            'photos/articles/makarska2022/makarska13.jpg',
            'photos/articles/makarska2022/makarska14.jpg',
            'photos/articles/makarska2022/makarska15.jpg',
            'photos/articles/makarska2022/makarska16.jpg',
        ],
        herci: [
            'photos/articles/herci/herci1.jpg',
            'photos/articles/herci/herci2.jpg',
            'photos/articles/herci/herci3.jpg',
        ],
        apa: [
            'photos/articles/apa/apa1.jpg',
            'photos/articles/apa/apa2.jpg',
            'photos/articles/apa/apa3.jpg',
            'photos/articles/apa/apa4.jpg',
            'photos/articles/apa/apa5.jpg',
            'photos/articles/apa/apa6.jpg',
            'photos/articles/apa/apa7.jpg',
            'photos/articles/apa/apa8.jpg',
            'photos/articles/apa/apa9.jpg',
            'photos/articles/apa/apa10.jpg',
            'photos/articles/apa/apa11.jpg',
            'photos/articles/apa/apa12.jpg',
        ],
        olomouckadrbna: [
            'photos/articles/olomouckadrbna/drbna1.jpg',
        ],
        tramtaria: [
            'photos/articles/tramtaria/tramtaria.jpg',
            'photos/articles/tramtaria/tramtaria2.jpg',
            'photos/articles/tramtaria/tramtaria3.jpg',
            'photos/articles/tramtaria/tramtaria4.jpg',
            'photos/articles/tramtaria/tramtaria5.jpg',
            'photos/articles/tramtaria/tramtaria6.jpg',
            'photos/articles/tramtaria/tramtaria7.jpg',
        ],
        zabavny_den_oc_hana: [
            'photos/articles/zabavny_den_oc_hana/ochana3.jpg',
            'photos/articles/zabavny_den_oc_hana/ochana1.jpg',
            'photos/articles/zabavny_den_oc_hana/ochana2.jpg',
            'photos/articles/zabavny_den_oc_hana/ochana7.jpg',
            'photos/articles/zabavny_den_oc_hana/ochana4.jpg',
            'photos/articles/zabavny_den_oc_hana/ochana5.jpg',
            'photos/articles/zabavny_den_oc_hana/ochana6.jpg',
            'photos/articles/zabavny_den_oc_hana/ochana8.jpg',
        ],
        canisterapie: [
            'photos/articles/canisterapie/canisterapie10.jpg',
            'photos/articles/canisterapie/canisterapie1.jpg',
            'photos/articles/canisterapie/canisterapie2.jpg',
            'photos/articles/canisterapie/canisterapie3.jpg',
            'photos/articles/canisterapie/canisterapie4.jpg',
            'photos/articles/canisterapie/canisterapie5.jpg',
            'photos/articles/canisterapie/canisterapie6.jpg',
            'photos/articles/canisterapie/canisterapie7.jpg',
            'photos/articles/canisterapie/canisterapie8.jpg',
            'photos/articles/canisterapie/canisterapie9.jpg',
            'photos/articles/canisterapie/canisterapie11.jpg',
            'photos/articles/canisterapie/canisterapie12.jpg',
            'photos/articles/canisterapie/canisterapie13.jpg',
            'photos/articles/canisterapie/canisterapie14.jpg',
        ],
        muzikoterapie: [
            'photos/articles/muzikoterapie/muzikoterapie1.jpg',
            'photos/articles/muzikoterapie/muzikoterapie2.jpg',
            'photos/articles/muzikoterapie/muzikoterapie3.jpg',
            'photos/articles/muzikoterapie/muzikoterapie4.jpg',
            'photos/articles/muzikoterapie/muzikoterapie5.jpg',
        ],
        stylova_party: [
            'photos/articles/stylova_party/party1.jpg',
            'photos/articles/stylova_party/party2.jpg',
            'photos/articles/stylova_party/party3.jpg',
            'photos/articles/stylova_party/party4.jpg',
            'photos/articles/stylova_party/party5.jpg',
            'photos/articles/stylova_party/party6.jpg',
            'photos/articles/stylova_party/party7.jpg',
            'photos/articles/stylova_party/party8.jpg',
            'photos/articles/stylova_party/party9.jpg',
            'photos/articles/stylova_party/party10.jpg',
            'photos/articles/stylova_party/party11.jpg',
            'photos/articles/stylova_party/party12.jpg',
            'photos/articles/stylova_party/party13.jpg',
            'photos/articles/stylova_party/party14.jpg',
            'photos/articles/stylova_party/party15.jpg',
            'photos/articles/stylova_party/party16.jpg',
            'photos/articles/stylova_party/party17.jpg',
            'photos/articles/stylova_party/party18.jpg',
            'photos/articles/stylova_party/party19.jpg',
        ],
        o_mori: [
            'photos/articles/omori/more1.jpg',
            'photos/articles/omori/more2.jpg',
            'photos/articles/omori/more3.jpg',
            'photos/articles/omori/more4.jpg',
            'photos/articles/omori/more5.jpg',
        ],
        ranapece: [
            'photos/sponzors/ranapece.png',
        ],
        roadventure: [
            'photos/articles/roadventure/road1.jpg'
        ],
        podekovani: [
            'photos/articles/podekovani/podekovani1.jpg',
            'photos/articles/podekovani/podekovani2.jpg',
            'photos/articles/podekovani/podekovani3.jpg',
            'photos/articles/podekovani/podekovani4.jpg',
            'photos/articles/podekovani/podekovani5.jpg',
            'photos/articles/podekovani/podekovani6.jpg',
            'photos/articles/podekovani/podekovani7.jpg',
            'photos/articles/podekovani/podekovani8.jpg',
            'photos/articles/podekovani/podekovani9.jpg',
            'photos/articles/podekovani/podekovani10.jpg',
            'photos/articles/podekovani/podekovani11.jpg',
            'photos/articles/podekovani/podekovani12.jpg',
            'photos/articles/podekovani/podekovani13.jpg',
        ],
        mohelnickelomy: [
            'photos/articles/mohelnickelomy/mohelnickelomy6.jpg',
            'photos/articles/mohelnickelomy/mohelnickelomy1.jpg',
            'photos/articles/mohelnickelomy/mohelnickelomy2.jpg',
            'photos/articles/mohelnickelomy/mohelnickelomy3.jpg',
            'photos/articles/mohelnickelomy/mohelnickelomy4.jpg',
            'photos/articles/mohelnickelomy/mohelnickelomy5.jpg',
            'photos/articles/mohelnickelomy/mohelnickelomy7.jpg',
            'photos/articles/mohelnickelomy/mohelnickelomy8.jpg',
            'photos/articles/mohelnickelomy/mohelnickelomy9.jpg',
            'photos/articles/mohelnickelomy/mohelnickelomy10.jpg',
            'photos/articles/mohelnickelomy/mohelnickelomy11.jpg',
        ],
        podekovani_oc_hana: [
            'photos/articles/podekovani_oc_hana/podekovaniochana1.jpg',
            'photos/articles/podekovani_oc_hana/podekovaniochana2.jpg',
            'photos/articles/podekovani_oc_hana/podekovaniochana3.jpg',
            'photos/articles/podekovani_oc_hana/podekovaniochana4.jpg',
            'photos/articles/podekovani_oc_hana/podekovaniochana5.jpg',
        ],
        podekovani_mestu_a_sokolove: [
            'photos/articles/podekovani_mestu_a_sokolove/podekovanimestuasokolove6.jpg',
            'photos/articles/podekovani_mestu_a_sokolove/podekovanimestuasokolove1.jpg',
            'photos/articles/podekovani_mestu_a_sokolove/podekovanimestuasokolove2.jpg',
            'photos/articles/podekovani_mestu_a_sokolove/podekovanimestuasokolove3.jpg',
            'photos/articles/podekovani_mestu_a_sokolove/podekovanimestuasokolove5.jpg',
            'photos/articles/podekovani_mestu_a_sokolove/podekovanimestuasokolove4.jpg',
        ],
        podekovani_stredisku_pro_ranou_peci: [
            'photos/articles/podekovani_stredisku_pro_ranou_peci/podekovani1.jpg',
            'photos/articles/podekovani_stredisku_pro_ranou_peci/podekovani2.jpg',
            'photos/articles/podekovani_stredisku_pro_ranou_peci/podekovani3.jpg',
            'photos/articles/podekovani_stredisku_pro_ranou_peci/podekovani4.jpg',
            'photos/articles/podekovani_stredisku_pro_ranou_peci/podekovani5.jpg',
            'photos/articles/podekovani_stredisku_pro_ranou_peci/podekovani6.jpg',
            'photos/articles/podekovani_stredisku_pro_ranou_peci/podekovani7.jpg',
            'photos/articles/podekovani_stredisku_pro_ranou_peci/podekovani8.jpg',
        ],
        navsteva_od_kuraray: [
            'photos/articles/navsteva_od_kuraray/navsteva3.jpg',
            'photos/articles/navsteva_od_kuraray/navsteva1.jpg',
            'photos/articles/navsteva_od_kuraray/navsteva2.jpg',
            'photos/articles/navsteva_od_kuraray/navsteva4.jpg',
            'photos/articles/navsteva_od_kuraray/navsteva5.jpg',
            'photos/articles/navsteva_od_kuraray/navsteva6.jpg',
            'photos/articles/navsteva_od_kuraray/navsteva7.jpg',
            'photos/articles/navsteva_od_kuraray/navsteva8.jpg',
        ],
        phm: [
            'photos/articles/phm/phm1.jpg'
        ],
        monoski: [
            'photos/articles/monoski/monoski10.jpg',
            'photos/articles/monoski/monoski1.jpg',
            'photos/articles/monoski/monoski2.jpg',
            'photos/articles/monoski/monoski3.jpg',
            'photos/articles/monoski/monoski4.jpg',
            'photos/articles/monoski/monoski5.jpg',
            'photos/articles/monoski/monoski6.jpg',
            'photos/articles/monoski/monoski7.jpg',
            'photos/articles/monoski/monoski8.jpg',
            'photos/articles/monoski/monoski9.jpg',
        ],
        ledovesochy: [
            'photos/articles/ledovesochy/sochy8.jpg',
            'photos/articles/ledovesochy/sochy1.jpg',
            'photos/articles/ledovesochy/sochy2.jpg',
            'photos/articles/ledovesochy/sochy3.jpg',
            'photos/articles/ledovesochy/sochy4.jpg',
            'photos/articles/ledovesochy/sochy5.jpg',
            'photos/articles/ledovesochy/sochy6.jpg',
            'photos/articles/ledovesochy/sochy7.jpg',
            'photos/articles/ledovesochy/sochy9.jpg',
            'photos/articles/ledovesochy/sochy10.jpg',
            'photos/articles/ledovesochy/sochy11.jpg',
            'photos/articles/ledovesochy/sochy12.jpg',
            'photos/articles/ledovesochy/sochy13.jpg'
        ],
        rampach: [
            'photos/articles/rampach/rampach1.jpg',
            'photos/articles/rampach/rampach2.jpg',
            'photos/articles/rampach/rampach3.jpg',
            'photos/articles/rampach/rampach4.jpg',
            'photos/articles/rampach/rampach5.jpg',
            'photos/articles/rampach/rampach6.jpg',
            'photos/articles/rampach/rampach7.jpg',
            'photos/articles/rampach/rampach8.jpg',
            'photos/articles/rampach/rampach9.jpg',
            'photos/articles/rampach/rampach10.jpg',
            'photos/articles/rampach/rampach11.jpg',
            'photos/articles/rampach/rampach12.jpg',
            'photos/articles/rampach/rampach13.jpg',
            'photos/articles/rampach/rampach14.jpg',
            'photos/articles/rampach/rampach15.jpg',
            'photos/articles/rampach/rampach16.jpg',
        ],
        muzikoterapie_ii: [
            'photos/articles/muzikoterapie2/muzikoterapie1.jpg',
            'photos/articles/muzikoterapie2/muzikoterapie2.jpg',
            'photos/articles/muzikoterapie2/muzikoterapie3.jpg',
            'photos/articles/muzikoterapie2/muzikoterapie4.jpg',
        ],
        lehatko: [
            'photos/articles/lehatko/lehatko1.jpg',
            'photos/articles/lehatko/lehatko2.jpg',
            'photos/articles/lehatko/lehatko3.jpg',
            'photos/articles/lehatko/lehatko4.jpg',
            'photos/articles/lehatko/lehatko5.jpg',
        ],
        stafeta: [
            'photos/articles/stafeta/stafeta1.jpg',
            'photos/articles/stafeta/stafeta2.jpg',
            'photos/articles/stafeta/stafeta3.jpg',
            'photos/articles/stafeta/stafeta4.jpg',
            'photos/articles/stafeta/stafeta5.jpg',
            'photos/articles/stafeta/stafeta6.jpg',
        ]
    }
}