import styled from "styled-components";
import { media } from "../../styles/media";
import { FONTS } from "../../styles/fonts";
import { COLORS } from "../../styles/colors";

export const HelpPage = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 140px 30px;
    background-color: ${COLORS.white};

    ${media.maxSm`
        flex-direction: column-reverse;
    `}
`

export const ImageSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 50%;

    ${media.maxSm`
        max-width: 100%;
    `}
`

export const HelpHeader = styled.h1`
    font-family: ${FONTS.primary};
    font-size: 45px;
    letter-spacing: 3px;
    margin-top: 0;
`

export const Image = styled.img`
    height: 720px;
    width: auto;
    margin-right: 80px;

    ${media.maxMd`
        margin-right: 65px;
    `}

    ${media.maxSm`
        margin-left: 0;
        max-width: 100%;
        height: auto;
        margin-bottom: 30px;
    `}
`

export const HelpText = styled.div`
    margin: 5px 0;
    font-family: ${FONTS.primary};
    font-size: 18px;
    letter-spacing: 1.2px;
    text-align: justify;
    line-height: 2rem;
`

export const SignatureKristianek = styled.span`
    font-family: ${FONTS.secondary};
    font-size: 70px;
    letter-spacing: 1.4px;
    line-height: 4.5rem;
`

export const SignatureFamily = styled.span`
    font-family: ${FONTS.secondary};
    font-size: 40px;
    letter-spacing: 1.3px;
    line-height: 4.rem;
`