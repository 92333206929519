import React from "react";

import { PublicImages } from "../../publicImages";
import * as S from './styled'
import BankAccount from "./BankAccount";

const infoText1 = 'Prosíme Vás o finanční příspěvek pro Kristiánka, který bojuje s těžkými zdravotními problémy. Pan Jaroslav Mašek a pan Pavel Strégl ze společnosti Exim Tours - Prostějov založili transparentní účet, kam můžete poslat jakoukoliv finanční částku.'
const infoText2 = 'Vaše finanční příspěvky budou použity na financování každoročního pobytu u moře pro Kristiana, který by měl být co nejdelší, aby mu pomohly posílit imunitu, zahojit ránu pegu, rehabilitovat a zmírnit astma. Dále pak na různé rehabilitační pomůcky, které potřebujeme ke každodennímu životu. Za jakoukoliv finanční podporu a sdílení jsem velmi vděční. Vaše pomoc je velmi ceněna a umožňuje Kristianovi dosáhnout lepší kvality života a zdraví.'
const infoText3 = 'Kamínky, oblázky a slaná voda Kristiánkovi velice přispívají ke stimulaci a zpevnění svalstva. Rehabilitace v moři je pro něj tou nejlepší přirozenou rehabilitací.'

const HelpPage = () => {
    return (
        <S.HelpPage>
            <S.Image src={PublicImages.kristianek3} />
            <S.TextContent>
                <S.HelpHeader>Vážení návštěvníci</S.HelpHeader>
                <S.HelpText>{infoText1}</S.HelpText>
                <BankAccount />
                <S.HelpText>{infoText2}</S.HelpText>
                <S.HelpText>{infoText3}</S.HelpText>
                <S.HelpText>Srdečně děkujeme</S.HelpText>
                <S.HelpText>
                    <S.SignatureKristianek>Kristianek Koucký</S.SignatureKristianek>
                    <S.SignatureFamily> a rodina</S.SignatureFamily>
                </S.HelpText>
            </S.TextContent>
        </S.HelpPage>
    )
}

export default HelpPage;