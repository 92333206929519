import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import FavoriteIcon from '@mui/icons-material/Favorite';

import styled from 'styled-components'

import { media } from '../../styles/media'
import { COLORS } from '../../styles/colors'

export const Header = styled.header<{ opened: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: ${COLORS.first};
  z-index: 999;

  
  ${({ opened }) => `${opened ? `opacity: 1;` : ''}`}
  
  ${media.maxMd`
    position: absolute;
  `}
`

export const HeaderSpacer = styled.div`
  margin: 0 100px;

  ${media.maxLg`
    margin: 0 30px;
  `}

  ${media.maxMd`
      margin: 0;
  `}
`

export const LogoMenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  color: ${COLORS.third};

  ${media.maxMd`
      width: 100%;
  `}
`

export const LogoLink = styled.a`
  color: ${COLORS.third};
  text-decoration: none;
`

export const LogoWrapper = styled.a`
  padding: 10px;
  color: ${COLORS.third};
`

export const DesktopLogo = styled.img`
  height: 75px;
  width: 267px;
  
  ${media.maxMd`
    display: none;
  `}
`

export const MobileLogo = styled.img`
  height: 50px;
  widht: 54px;

  ${media.md`
    display: none;
  `}
`

export const HeaderLinks = styled.nav`
  margin-right: 0;
  margin-left: auto;

  ${media.maxMd`
    display: none;
  `}
`

export const DesktopMenuContainer = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
`

export const MenuLink = styled.a<{ underline: boolean }>`
  text-decoration: ${({ underline }) => underline ? 'underline;' : 'none;'}
  color: ${COLORS.white};
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.42px;
  opacity: 1;
  margin-left: 30px;
  text-transform: uppercase;

  &:hover {
      text-decoration: underline;
  }
`

export const MenuButton = styled.button`
  background-color: transparent;
  border: none;
`

export const MenuToggleIcon = styled(MenuIcon)`
  margin-right: 5%;

  ${media.md`
    display: none !important;
  `}
`

export const CloseMenuIcon = styled(CloseIcon)`
  margin-right: 5%;

  ${media.md`
    display: none !important;
  `}
`

export const HeartIcon = styled(FavoriteIcon)`
  width: 50px;
  height: 50px;
`

export const OpenedMobileMenuWrapper = styled.div<{ opened: boolean }>`
  background-color: ${COLORS.first};
  height: 100vh;
  ${({ opened }) => `display: ${opened ? 'block' : 'none'};`}
`

export const Navigation = styled.nav`
  width: 100%;
`

export const MobileMenuList = styled.ul`
    margin: 0;
    padding: 0;
`

export const MobileMenuItem = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`

export const MobileMenuLink = styled.a`
  text-align: center;
  font-weight: normal;
  letter-spacing: 0.42px;
  color: #fff;
  opacity: 1;
  margin: 32px 0;
  text-decoration: none;
  font-size: 1.5rem;
`

export const Title = styled.h1`
  font-size: 2rem;

  ${media.maxXs`
    display: none;
  `}

  &:hover {
    cursor: pointer;
  }
`