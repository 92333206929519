import React from "react";

import { PublicImages } from "../../../publicImages";
import * as S from './styled'

const SponzorsList = [
    {
        id: '1',
        image: PublicImages.sponzors.eximtours,
        url: 'https://www.eximtours.cz/'
    },
    {
        id: '2',
        image: PublicImages.sponzors.optika_wagner,
        url: 'https://optikawagner.cz/'
    },
    {
        id: '3',
        image: PublicImages.sponzors.agel_nadace,
        url: 'https://nadace.agel.cz/'
    },    {
        id: '4',
        image: PublicImages.sponzors.peugeot,
        url: 'https://www.peugeot.cz/'
    },
    {
        id: '5',
        image: PublicImages.sponzors.ranapece,
        url: 'https://www.ranapece.cz/'
    },
    {
        id: '6',
        image: PublicImages.sponzors.dobryandel,
        url: 'https://www.dobryandel.cz/'
    },
    {
        id: '7',
        image: PublicImages.sponzors.dekazlasky,
        url: 'https://www.dekyzlasky.cz/'
    },
    {
        id: '8',
        image: PublicImages.sponzors.ochana,
        url: 'https://ochana.cz/'
    },
    {
        id: '9',
        image: PublicImages.sponzors.cernikone,
        url: 'https://www.cernikone.cz/'
    },
    {
        id: '10',
        image: PublicImages.sponzors.dobremistoprozivot,
        url: 'https://www.dobremistoprozivot.cz/'
    },
    {
        id: '11',
        image: PublicImages.sponzors.rejnet,
        url: 'https://www.rejnet.cz/'
    },
    {
        id: '12',
        image: PublicImages.sponzors.beestyle,
        url: 'https://www.beestyle.cz/'
    },
    {
        id: '13',
        image: PublicImages.sponzors.justcs,
        url: 'https://nadacejust.cz/'
    },
    {
        id: '14',
        image: PublicImages.sponzors.kuraray,
        url: 'https://www.kuraray.com/'
    },
    {
        id: '15',
        image: PublicImages.sponzors.ksm,
        url: 'https://www.klubsvobodnychmatek.cz/'
    },
    {
        id: '16',
        image: PublicImages.sponzors.roadventurerally,
        url: 'https://roadventure.eu/'
    },
    {
        id: '17',
        image: PublicImages.sponzors.nadaceagrofert,
        url: 'https://www.nadace-agrofert.cz/'
    }
]


const Sponzors = () => {
    return (
        <>
            <S.Sponzors>
                <S.Title>Naši sponzoři</S.Title>
            </S.Sponzors>
            <S.SponzorsList>
                {SponzorsList.map((value) => (
                    <S.ImageWrapper key={value.id} href={value.url} target="_blank">
                        <S.Image src={value.image} />
                    </S.ImageWrapper>
                ))}
            </S.SponzorsList>
        </>
    )
}

export default Sponzors