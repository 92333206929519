import React, { useState } from 'react'

import InfoBanner from './InfoBanner'

import * as S from './styled'

type InfoBoxProps = {
    success: boolean
    show: boolean
}

const ContactPage = () => {
    const [ isEmailValid, setIsEmailValid ] = useState(true)
    const [ isMessageValid, setIsMessageValid ] = useState(true)
    const [ isNameValid, setIsNameValid ] = useState(true)

    const [ emailValue, setEmailValue ] = useState('')
    const [ phoneValue, setPhoneValue ] = useState('')
    const [ messageValue, setMessageValue ] = useState('')
    const [ nameValue, setNameValue ] = useState('')

    const [ showLoader, setShowLoader ] = useState(false)
    const [ infoBoxShown, setInfoBoxShown ] = useState<InfoBoxProps>({
        success: true,
        show: false
    })


    const validateEmail = () => {
        const emailExpression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        setIsEmailValid(emailExpression.test(emailValue))
    }

    const validateMessage = () => {
        setIsMessageValid(messageValue?.trim() !== '')
    }

    const validateName = () => {
        setIsNameValid(nameValue?.trim() !== '')
    }

    const onSubmitForm = async (event: React.FormEvent) => {
        event.preventDefault()
        setInfoBoxShown({
            success: true,
            show: false
        })
        setShowLoader(true)
        
        if (!isEmailValid || !isMessageValid) {
            return
        }

        const body = {
            'email': emailValue,
            'phone': phoneValue ?? '',
            'message': `${nameValue} zasílá vzkaz:\n${messageValue}`
        }

        const response = await fetch('sendcontactform.php', {
            method: 'POST',
            body: JSON.stringify(body),
        })

        type Result = {
            result: string
        }

        const result: Result = await response.json();

        setShowLoader(false)
        if (result.result === '1') {
            setInfoBoxShown({
                success: true,
                show: true
            })

            setNameValue('')
            setEmailValue('')
            setPhoneValue('')
            setMessageValue('')
        } else {
            setInfoBoxShown({
                success: false,
                show: true
            })
        }
    }

    return (
        <S.ContactPage>
            <S.ContactInfoBox>
                <S.Title>Kontakt</S.Title>
                <S.Text><S.Bold>E-mail</S.Bold>: alca1968@seznam.cz</S.Text>
                <S.Text><S.Bold>Tel.</S.Bold>: +420 606 931 121</S.Text>
                <S.Text>Prostějov</S.Text>
            </S.ContactInfoBox>
            <S.ContactFormBox>
                <InfoBanner values={infoBoxShown} />
                <S.LoaderWrapper display={showLoader}>
                    <S.Loader />
                </S.LoaderWrapper>
                <form onSubmit={(event) => onSubmitForm(event)}>
                    <S.CustomTextField required label="Jméno/Organizace" variant="outlined" value={nameValue} sx={{ minWidth: '300px' }} margin='normal' valid={isNameValid} onBlur={validateName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNameValue(event.target.value)} />
                    <S.CustomTextField required label="E-mail" variant="outlined" value={emailValue} sx={{ minWidth: '300px' }} margin='normal' valid={isEmailValid} onBlur={validateEmail} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmailValue(event.target.value)} />
                    <S.CustomTextField label="Telefon (nepovinné)" variant="outlined" value={phoneValue} margin='normal' valid={true} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhoneValue(event.target.value)} />
                    <S.CustomTextField required label="Zpráva" variant="outlined" value={messageValue} multiline rows={6} margin='normal' valid={isMessageValid} onBlur={validateMessage} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMessageValue(event.target.value)}  />
                    <S.SubmitButton type='submit' value='Odeslat'></S.SubmitButton>
                </form>
            </S.ContactFormBox>
        </S.ContactPage>
    )
}

export default ContactPage