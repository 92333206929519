import Header from './components/header';
import Footer from './components/footer';
import MainPage from './pages/MainPage';
import HelpPage from './pages/HelpPage';
import ContactPage from './pages/ContactPage';

import * as S from './styled'
import BlogPage from './pages/BlogPage';
import FullArticle from './pages/FullArticle';
import { ArticleType, Articles } from './articles';
import { PublicImages } from './publicImages';
import dayjs from 'dayjs';

function App() {
  const page = document.getElementById('root')?.getAttribute('page')
  let content = <MainPage />
  if (page === 'contact') {
    content = <ContactPage />
  } else if (page === 'help') {
    content = <HelpPage />
  } else if (page === 'blog') {
    content = <BlogPage />
  } else if (page === 'article') {
    const id = Number(document.getElementById('root')?.getAttribute('aid'))
    const article = Articles.find(article => article.id === id) ?? Articles[0]
    content = <FullArticle article={article} />
  } else if (page === 'about') {
    const article: ArticleType = {
        id: 0,
        title: 'Ze života Kristianka',
        images: PublicImages.articles.zezivota,
        text: '',
        date: dayjs('2013-06-13'),
        url: 'about'
    }

    content = <FullArticle article={article} />
  }

  return (
    <S.App>
      <Header current={page ?? 'index'} />
      {content}
      <Footer />
    </S.App>
  );
}

export default App;
