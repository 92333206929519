
import { PublicImages } from '../../publicImages'
import * as S from './styled'

const Footer = () => {
    return (
        <S.Footer>
            <S.Copyright>© 2023 created by <S.Link href='https://beestyle.cz' target='_blank'>BeeStyle</S.Link></S.Copyright>
            <S.SocialMediasWrapper>
                <S.SocialMediaButton href='https://www.facebook.com/groups/313173242204133/' target="_blank" >
                    <S.SocialMediaIcon src={PublicImages.facebook} />
                </S.SocialMediaButton>
                <S.SocialMediaButton href='https://www.instagram.com/pomocprokristianka2013/' target="_blank" >
                    <S.SocialMediaIcon src={PublicImages.instagram} />
                </S.SocialMediaButton>
            </S.SocialMediasWrapper>
        </S.Footer>
    )
}

export default Footer