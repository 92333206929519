import { useState } from 'react'
import { Box } from '@mui/material'

import * as S from './styled'

type Props = {
    current: string
}

type NavigationItem = {
    id: string
    label: string
    link: string
}

const navItems: NavigationItem[] = [
    {
        id: '1',
        label: 'Úvod',
        link: './index.html'
    },
    {
        id: '6',
        label: 'Ze Života',
        link: './about.html'
    },
    {
        id: '2',
        label: 'Přispět',
        link: './help.html'
    },
    {
        id: '4',
        label: 'Blog',
        link: './blog.html'
    },
    {
        id: '5',
        label: 'Kontakt',
        link: './contact.html'
    },
]

const Header: React.FC<Props> = ({ current }) => {
    const [ mobileMenuOpened, setMobileMenuOpened ] = useState(false)

    return (
        <S.Header opened={mobileMenuOpened}>
            <S.HeaderSpacer>
                <Box sx={{ display: 'flex', m: 0, p: 0, alignItems: 'center' }}>
                    <S.LogoMenuHeader>
                        <S.LogoWrapper href='./index.html'>
                            <S.HeartIcon />
                        </S.LogoWrapper>
                        <S.Title><S.LogoLink href='./index.html'>Kristianek Koucký</S.LogoLink></S.Title>
                        <S.MenuButton onClick={() => setMobileMenuOpened(!mobileMenuOpened)} aria-label='open and close menu icon'>
                            {mobileMenuOpened ? <S.CloseMenuIcon sx={{ color: 'white', fontSize: '60px' }} /> : <S.MenuToggleIcon sx={{ color: 'white', fontSize: '60px' }} />}
                        </S.MenuButton>
                    </S.LogoMenuHeader>

                    <S.HeaderLinks>
                        <S.DesktopMenuContainer>
                            {navItems.map((item) => (
                                <li key={item.id}>
                                    <S.MenuLink href={item.link} underline={item.link.includes(current)}>{item.label}</S.MenuLink>
                                </li>
                            ))}
                        </S.DesktopMenuContainer>
                    </S.HeaderLinks>
                </Box>
            </S.HeaderSpacer>

            <S.OpenedMobileMenuWrapper opened={mobileMenuOpened}>
                <Box sx={{ display: 'flex', m: 0, p: 0, alignItems: 'center', flexDirection: 'column' }}>
                    <S.Navigation>
                        <S.MobileMenuList>
                            {navItems.map((item) => (
                                <S.MobileMenuItem key={item.id}>
                                    <S.MobileMenuLink href={item.link} onClick={() => setMobileMenuOpened(false)}>
                                        {item.label}
                                    </S.MobileMenuLink>
                                </S.MobileMenuItem>
                            ))}
                        </S.MobileMenuList>
                    </S.Navigation>
                </Box>
            </S.OpenedMobileMenuWrapper>
        </S.Header>
    )
}

export default Header