import styled from 'styled-components'

import { CircularProgress, TextField } from '@mui/material'
import { media } from '../../styles/media'
import { FONTS } from '../../styles/fonts'
import { COLORS } from '../../styles/colors'

export const ContactPage = styled.div`
    display: flex;
    flex-direction: row;
    aling-items: stretch;
    justify-content: space-evenly;
    margin: 0;
    padding: 140px 30px;
    background-color: ${COLORS.white};

    ${media.maxMd`
        flex-direction: column;
    `}
`

export const ContactInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const Title = styled.h1`
    font-family: ${FONTS.primary};
    font-size: 60px;
    letter-spacing: 3px;
    margin-top: 0;
    color: ${COLORS.black};
    font-weight: 800;
`

export const Text = styled.p`
    font-family: ${FONTS.primary};
    font-size: 20px;
    margin: 8px 0;
    padding: 0;
`

export const Bold = styled.span`
    font-weight: 600;
`

export const ContactFormBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;

    ${media.maxMd`
        margin: 20px 0;
    `}
`

export const LoaderWrapper = styled.div<{ display: boolean }>`
    ${({ display }) => display ? '' : 'display: none;'}
    position: relative;
    width: 100%;
    height: 90px;
`

export const Loader = styled(CircularProgress)`
    position: absolute;
    top: 0;
    left: 50%;
`

export const CustomTextField = styled(TextField)<{ valid: boolean }>`
    width: 100%;
    fieldset {
        ${({ valid }) => !valid ? `border-color: red;` : ''}
    }
`

export const SubmitButton = styled.input`
    width: 200px;
    height: 50px;
    border-radius: 0px;
    border-color: ${COLORS.black};
    border-style: solid;
    font-family: ${FONTS.primary};
    font-size: 25px;
    color: ${COLORS.black};
    background-color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 10px;

    ${media.md`
        &:hover {
            color: ${COLORS.white};
            background-color: ${COLORS.first};
            border-color: ${COLORS.first};
            transition: 0.7s;
            cursor: pointer;
        }
    `}

    ${media.maxMd`
        color: ${COLORS.white};
        background-color: ${COLORS.first};
        border-color: ${COLORS.first};
    `}
`