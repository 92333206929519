import styled from 'styled-components'
import { COLORS } from '../../../styles/colors'
import { FONTS } from '../../../styles/fonts'
import { media } from '../../../styles/media'

export const AboutSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Title = styled.h1`
    font-family: ${FONTS.secondary};
    font-size: 60px;
    letter-spacing: 3px;
    margin-top: 0;
    color: ${COLORS.black};
    font-weight: 800;
`

export const ContentSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    ${media.maxSm`
        flex-direction: column;
        align-items: center;
    `}
`

export const Image = styled.img`
    width: 462px;
    height: 576px;

    ${media.md`
        transform: skewX(10deg);
    `}

    ${media.maxSm`
        max-width: 100%;
        height: auto;
    `}
`

export const Text = styled.div`
    font-family: ${FONTS.primary};
    font-size: 18px;
    letter-spacing: 1.2px;
    margin-top: 35px;
    text-align: justify;
    line-height: 2.5rem;
    
    ${media.sm`
        border-bottom: solid 15px ${COLORS.second};
        border-top: solid 15px ${COLORS.second};
        padding: 30px 0;
        margin-left: 65px;
        margin-top: 0px;
    `}

    ${media.md`
        margin-left: 100px;
        max-width: 500px;
    `}
`