import React from "react";

import { PublicImages } from "../../../publicImages";

import * as S from './styled'

const text = 'Kristianek se narodil v roce 2013 císařským řezem jako zdanlivě zdravé miminko, avšak 4. den po porodu se začaly objevovat první zdravotní potíže. Po několika následných vyšetřeních se zjistilo, že trpí kombinovanym onemocněním. Od 8 měsíců věku má Kristiánek PEG (sondu na enterální výživu přes hadičku přímo do žaludku, kterou má dosud). V září 2020 nastoupil do 1. třídy speciální školy s IVP vzděláváním. I přes všechna zdravotní komplikace a omezení si Kristiánek umí druhé získat skrze svůj úsměv.'

const AboutSection = () => {
    return (
        <S.AboutSection>
            <S.Title>Vítejte</S.Title>
            <S.ContentSection>
                <S.Image src={PublicImages.kristianek} />
                <S.Text>{text}</S.Text>
            </S.ContentSection>
        </S.AboutSection>
    )
}

export default AboutSection