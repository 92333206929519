import styled from 'styled-components'

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';

export const Footer = styled.footer`
    padding: 15px 0;
    background-color: ${COLORS.first};
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    bottom: 0;
    height: 100px;
`

export const Copyright = styled.h2`
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: normal;
    text-align: center;
    font-family: ${FONTS.primary}
`

export const SocialMediasWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
`

export const SocialMediaButton = styled.a`
    margin: 0;
    padding: 0;

    &:hover {
        background: none;
        cursor: pointer;
    }
`

export const SocialMediaIcon = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 15px;
`

export const Link = styled.a`
    text-decoration: none;
    color: ${COLORS.white};
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: normal;
    text-align: center;
    font-family: ${FONTS.primary};

    &:hover {
        text-decoration: underline;
    }
`

