import styled from "styled-components";
import { FONTS } from "../../../styles/fonts";
import { COLORS } from "../../../styles/colors";
import { media } from "../../../styles/media";

export const LetterSection = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 100px;

    ${media.maxSm`
        flex-direction: column-reverse;
        align-items: center;
    `}
`

export const Image = styled.img`
    width: 462px;
    height: 576px;

    ${media.maxMd`
        margin-left: 65px;
    `}

    ${media.maxSm`
        margin-left: 0;
        max-width: 100%;
        height: auto;
        margin-bottom: 30px;
    `}
`

export const Letter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: ${FONTS.primary};

    ${media.sm`
        max-width: 60%;
    `}
`

export const Title = styled.h2`
    font-family: ${FONTS.secondary};
    font-size: 40px;
    letter-spacing: 3px;
    margin-top: 0;
    color: ${COLORS.black};
`

export const LetterContent = styled.div`
    font-family: ${FONTS.primary};
    font-size: 18px;
    letter-spacing: 1.5px;
    margin-bottom: 30px;
    border-radius: 20px;
    line-height: 2.2rem;
    text-align: justify;

    ${media.maxMd`
        letter-spacing: 2px;
        font-size: 16px;
        font-family: ${FONTS.primary};
    `}

    ${media.md`
        line-height: 2.rem;
        max-width: 75%;
    `}
`