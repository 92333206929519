import React from 'react'

import { PublicImages } from '../../../publicImages'
import * as S from './styled'

const BankAccount = () => {
    return (
        <S.BankAccount>
            <S.ColumnContent>
                <S.RowContent>
                    <S.ColumnContent>
                        <S.Text><S.Bold>Údaje k účtu:</S.Bold></S.Text>
                        <S.Text><S.Bold>Číslo účtu:</S.Bold> 2901714229/2010</S.Text>
                        <S.Text><S.Bold>Majitel účtu:</S.Bold> 012 spol. s r.o.</S.Text>
                    </S.ColumnContent>
                    <S.QRCodeDesktop src={PublicImages.qr} />
                </S.RowContent>
                <S.Text><S.Bold>Název účtu:</S.Bold> 012 spol. s r.o. - Kristian Koucký, nar. 13. 6. 2013</S.Text>
                <S.QRCodeMobile src={PublicImages.qr} />
            </S.ColumnContent>
        </S.BankAccount>
    )
}

export default BankAccount