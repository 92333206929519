import styled from "styled-components";
import { FONTS } from "../../../styles/fonts";
import { COLORS } from "../../../styles/colors";

export const Sponzors = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0 0;
`

export const Title = styled.h2`
    font-family: ${FONTS.primary};
    font-size: 40px;
    letter-spacing: 3px;
    margin-top: 0;
    color: ${COLORS.black};
    text-transform: uppercase;
    bold: 800;
`

export const SponzorsList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 360px);
    grid-auto-columns: auto;
    grid-gap: 10px 40px;
    justify-content: center;
    align-items: center;
`

export const ImageWrapper = styled.a`

`

export const Image = styled.img`
    width: 360px;
    height: auto;
`