import React from "react";

import * as S from './styled'
import { PublicImages } from "../../../publicImages";

const getAge = () => {
    const birthdate = new Date(2013, 5, 13)
    const currentDate = new Date()
    let age = currentDate.getFullYear() - birthdate.getFullYear()
    
    if (
        currentDate.getMonth() < birthdate.getMonth() ||
        (currentDate.getMonth() === birthdate.getMonth() && currentDate.getDate() < birthdate.getDate())
    ) {
        age--
    }

    return age
}

const text = `Každý rodič se raduje z narození dítěte a stejně tak tomu bylo i v mém případě. Narodil jsem se jako zdravé miminko. Bohužel jsem zanedlouho začal být nemocný. Mám nejen metabolické onemocnění, ale také hypotonii, hypotrofii, hepatopatii, mitochondriální onemocnění, FoA , Ehlers-Danlosův syndrom susp. Trpěl jsem těžkou formou plagiocefalie komplikovanou metabolickým onemocněním. Mám diagnostikovaný PAS-poruchu autistického spektra, JIA -Juvenilní idiopatickou artiktidu, zelený zákal, laidenskou mutaci-riziko trombozy, histaminovou intoleranci. V 8 měsících mi operovali do bříška PEG -sondu na enterální výživu přes hadičku přímo do žaludku, kterou mám dosud. Zatím ještě nejsem od narození ze zdravotních duvodů očkovaný. No, není to žádná sranda. Abychom dosáhli co nejlepšího celkového vývoje, začali jsme co nejdříve cvičit vojtovu metodu, později bobath koncept, který cvičíme dosud. Už jsem oslavil ${getAge()}. narozeniny. Bohužel mám špatnou imunitu a tak nemůžu moc mezi lidi. Naštěstí mám skvělou maminku, která se mnou jezdí na výlety a snaží se mě začlenit do společnosti jak to jen jde. Přesto všechno jsem veselý a šťastný kluk, který se umí radovat z maličkostí a dělá mamince radost. Bydlíme i s maminkou a starším bratrem v menším nájemním bytě. Ranná péče je nám na této nelehké cestě, naším velký rádcem, pomocníkem, sponzorem, takovou naší druhou rodinou. V roce 2019 jsem měl odklad školní docházky v roce 2020 jsem nastoupil do 1. třídy speciální školy, kde mám IVP.`

const LetterSection = () => {
    return (
        <S.LetterSection>
            <S.Letter>
                <S.Title>O Kristiankovi:</S.Title>
                <S.LetterContent>{text}</S.LetterContent>
            </S.Letter>
            <S.Image src={PublicImages.kristianek2} />
        </S.LetterSection>
    )
}

export default LetterSection