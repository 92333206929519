import styled from 'styled-components'
import { COLORS } from '../../styles/colors'

export const BlogPage = styled.div`
    margin: 0;
    padding: 140px 30px;
    background-color: ${COLORS.white};
    display: grid;
    grid-template-columns: repeat(auto-fit, 360px);
    grid-auto-columns: auto;
    grid-gap: 70px 40px;
    justify-content: center;
    align-items: center;
`