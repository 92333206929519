import { ResponsiveMasonry } from 'react-responsive-masonry'

import styled from 'styled-components'
import { media } from '../../styles/media';
import { FONTS } from '../../styles/fonts';
import { COLORS } from '../../styles/colors';

export const FullArticle = styled.div`
    margin: 0;
    padding: 140px 30px;
    background-color: ${COLORS.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    ${media.maxMd`
        padding: 140px 100px 50px;
    `}
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

export const TextContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
`

export const ImageList2 = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, 360px);
    grid-auto-columns: auto;
    grid-gap: 20px 40px;
    justify-content: center;
    align-items: flex-start;
`

export const Image = styled.img`
    width: 250px;
    height: auto;
`

export const Title = styled.h1`
    font-family: ${FONTS.primary};
    font-size: 60px;
    letter-spacing: 3px;
    margin: 0 0 10px;
    color: ${COLORS.black};
    font-weight: 800;

    ${media.maxMd`
        font-size: 25px;
    `}
`

export const Text = styled.p`
    font-family: ${FONTS.primary};
    font-size: 16px;
`

export const ImageList = styled(ResponsiveMasonry)`
    width: 255px;
    margin: 0;

    ${media.imageXs`
        width: 510px;
    `}

    ${media.imageSm`
        width: 765px;
    `}

    ${media.imageMd`
        width: 1020px;
    `}
`