import React from 'react'

import ArticleView from './ArticleView'
import { Articles } from '../../articles'

import * as S from './styled'

const BlogPage = () => {
    return (
        <S.BlogPage>
            {Articles.sort((a, b) => a.date.isBefore(b.date) ? 1 : -1).map((article) => (
                <ArticleView key={article.id} article={article} />
            ))}
        </S.BlogPage>
    )
}

export default BlogPage