import React from 'react'

import Masonry from 'react-responsive-masonry'

import * as S from './styled'
import { ArticleType } from '../../articles'

type Props = {
    article: ArticleType
}

const FullArticle: React.FC<Props> = ({ article }) => {
    return (
        <S.FullArticle>
            <S.Content>
                <S.TextContent>
                    <S.Title>{article.title}</S.Title>
                    <S.Text>{article.date.format('DD. MM. YYYY')}</S.Text>
                    {article.text.split('\n').map((t, i) => <S.Text key={i}>{t}</S.Text>)}
                </S.TextContent>
                <S.ImageList columnsCountBreakPoints={{255: 1, 530: 2, 765: 3, 1020: 4}}>
                    <Masonry gutter="5px">
                        {article.images.map((image, i) => {
                            console.log(`rendering ${image}`)
                            return <S.Image key={i} src={image} alt={image.split('/').pop()?.split('.')[0]} />
                    })}
                    </Masonry>
                </S.ImageList>
            </S.Content>
        </S.FullArticle>
    )
}

export default FullArticle