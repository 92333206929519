import styled from 'styled-components'
import { FONTS } from '../../../styles/fonts'
import { media } from '../../../styles/media'

export const BankAccount = styled.div`
    margin: 30px 0;
    font-family: ${FONTS.primary};
    font-size: 18px;
    letter-spacing: 1.2px;
    text-align: left;
    line-height: 2rem;
`

export const RowContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const QRCodeDesktop = styled.img`
    height: 120px;
    width: 120px;
    margin-left: 50px;

    ${media.maxSm`
        display: none;
    `}
`

export const QRCodeMobile = styled.img`
    width: 60%;
    height: auto;
    margin: 10px 0 0;

    ${media.sm`
        display: none;
    `}
`

export const ColumnContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

export const Text = styled.p`
    margin: 3px 0;
    padding: 0;
`

export const Bold = styled.span`
    font-weight: 700;
`