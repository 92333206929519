import { PublicImages } from '../../publicImages'
import AboutSection from './aboutSection'
import LetterSection from './letterSection'
import Sponzors from './sponzors'
import * as S from './styled'

const MainPage = () => {
    return (
    <S.Main>
        <AboutSection />
        <S.FbVidWrapper>
            <S.Text>Už se nám to blíží, na přípravách usilovně pracujeme. Tak Vám přidáme pár důvodů proč se zúčastnit. Samozřejmě bude spousta dobrého jídla, skvělé muziky, nebude chybět soutěž na našem divokém býku, svoje umění přijede předvést Lukáš Petráček se svou stunttrialshow, na hřiště opět seskočí parašutisté, a to nejdůležitější: veškerý výtěžek z akce bude věnován letos nově TŘEM dětem a to Leona Pochopova, Kájovi Vitáskovi a poprvé i Kristiánkovi Kouckému</S.Text>
            <S.FbVid src='https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fjana.vitaskova.3%2Fvideos%2F452575647331138%2F&show_text=false&width=560&t=0' allowFullScreen={true} />
            <S.Image src={PublicImages.poster} />
        </S.FbVidWrapper>
        <LetterSection />
        <Sponzors />
    </S.Main>
    )
}

export default MainPage

