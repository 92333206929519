import styled from 'styled-components'

import { Card, CardActions, CardContent, CardMedia } from '@mui/material'
import { FONTS } from '../../../styles/fonts'

export const ArticleView = styled(Card)`
    width: 360px;
    font-family: ${FONTS.primary};
`

export const CardImage = styled(CardMedia)<{ position: string }>`
    height: 200px;
    ${({ position }) => `background-position: ${position};`}
`

export const CardText = styled(CardContent)`
    height: 180px;
`

export const CardButtons = styled(CardActions)`

`

export const Title = styled.p`
    font-weight: 600;
    font-size: 1.2rem;
    height: 50px;
`

export const DateText = styled.p`
    font-size: 13px;
`

export const Link = styled.a`
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.75;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    padding: 4px 5px;
    font-family: ${FONTS.primary};
    color: #1976d2;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`