import React from 'react'

import { ArticleType } from '../../../articles'
import { CardActions } from '@mui/material'

import * as S from './styled'

type Props = {
    article: ArticleType
}

const ArticleView: React.FC<Props> = ({ article }) => {
    return (
        <S.ArticleView>
            <S.CardImage image={article.images[0]} title={article.title} position={article.position ?? 'center'} />
            <S.CardText>
                <S.Title>{article.title}</S.Title>
                <S.DateText>{article.date.format('D. M. YYYY')}</S.DateText>
                {article.text.substring(0, 80).replaceAll('\n', ' ')}...
            </S.CardText>
            <CardActions>
                <S.Link href={`./${article.url}.html`}>Přečíst</S.Link>
            </CardActions>
        </S.ArticleView>
    )
}

export default ArticleView